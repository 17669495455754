<template>
  <div class="vg_wrapper">
    <el-tabs :value="'first'" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <AddHeader @submit="submit()" @cancel="cancel">
          <div slot="otherButton">
            <div class="flexHV vg_mr_8">
              <span>材料询价单编号：</span>
              <el-input disabled size="small" :value="maco_no" style="width: 160px"></el-input>
            </div>
          </div>
          <div slot="specialStatus" style="display: flex">
            <div class="flexHV vg_mr_8">
              <span>报价状态：</span>
              <el-tag :type="getStatusName(orderStatus).type">{{ getStatusName(orderStatus).name }}</el-tag>
            </div>
            <div class="flexHV">
              <span>状态：</span>
              <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
            </div>
          </div>
        </AddHeader>
        <DynamicForm
          v-bind="$attrs"
          :total-width="mainForm.totalWidth"
          :split="mainForm.split"
          :form-list="mainForm.formProperties"
          ref="dynamicForm"
          class="dynamicForm"
          :is-show="false"
        />
        <div>
          <el-button class="vg_mb_8" type="primary" size="small" @click="ESFDialogVisible = true">导入委托打样单</el-button>
          <el-button class="vg_mb_8" type="primary" size="small" @click="addRow()">新增</el-button>
          <el-button class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable"
          :tableData="subTableData"
          :columns="macoAddSubTableProperties"
          :need-pagination="false"
          :is-show="false"
          @selection-change="val => (subSelections = val)"
        >
        </DynamicUTable>
        <el-dialog title="导入委托打样单" :visible.sync="ESFDialogVisible" width="70%" @opened="getESFTableData">
          <div class="vg_mb_8">
            <el-button type="info" size="small" icon="el-icon-refresh-right" @click="ESFDialogRefresh"> 刷新</el-button>
          </div>
          <search-table
            ref="ESFTable"
            :data="ESFTableData"
            :tableRowKey="'ESFDialogInfo'"
            :columns="ESFTableProperties"
            v-loading="ESFLoadFlag"
            :total-page="ESFTotalPage"
            @row-dblclick="importESFConfirm"
            @getTableData="getESFTableData"
          >
          </search-table>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddHeader from '@/views/component/addHeader.vue';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { macoAPI } from '@api/modules/maco';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { ESFTableProperties, macoAddSubTableProperties, macoMainFormProperties } from '@/views/SalesManagement/MacoManage/maco';
import { getCustomerInfo, getDept, getStff, getSupp, getUnit } from '@api/public';
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { deleteTableRow } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'MacoAdd',
  components: { SearchTable, DynamicUTable, DynamicForm, AddHeader },
  data() {
    return {
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(macoMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      macoAddSubTableProperties: cloneDeep(macoAddSubTableProperties),
      formStatus: 0,
      orderStatus: 0,
      mesaStatus: 0,
      maco_no: '',
      maco_id: '',
      ESFDialogVisible: false,
      ESFTableData: [],
      ESFTableProperties: cloneDeep(ESFTableProperties),
      ESFLoadFlag: false,
      ESFTotalPage: 0,
      tenDay: 864000000,
      sevenDay: 604800000,
      twentyDay: 1728000000
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.changeProperties();
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'cust_id').options = await getCustomerInfo({ status: 2, type: 'abbr' });

      let bDept = this.mainForm.formProperties.find(({ prop }) => prop === 'business_dept_id');
      let yewu = await getDept('业务');
      let neimao = await getDept('内贸');
      let dianshang = await getDept('电商');
      bDept.options = [...yewu, ...neimao, ...dianshang];
      bDept.model = Number(this.$cookies.get('userInfo').dept_id);

      let stffList = await getStff('辅料');
      let podrStff = this.mainForm.formProperties.find(({ prop }) => prop === 'podr_stff_id');
      podrStff.options = stffList;
      podrStff.model = stffList.find(({ label }) => label === '臧小妹')?.value || '';

      let mtrb_pctype = this.macoAddSubTableProperties.find(({ prop }) => prop === 'mtrb_pctype');
      getNoCatch(optnAPI.getOptnByPermIdV1, { perm_id: 10024 }).then(({ data }) => {
        mtrb_pctype.subItem.options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
      mtrb_pctype.subItem.change = (val, row) => {
        if (val === '布标' || val === '织标') {
          row.arrival_date = Date.now() + this.tenDay;
        } else {
          row.arrival_date = Date.now() + this.twentyDay;
        }
      };

      this.macoAddSubTableProperties.find(({ prop }) => prop === 'maco_unit').subItem.options = await getUnit('select', 'param1');

      let suppList = await getSupp('valueList');
      this.macoAddSubTableProperties.find(({ prop }) => prop === 'mtrb_abbr').subItem.options = {
        0: { value: 0, label: '待告' },
        ...suppList
      };
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$refs.dynamicForm.reset();
          await this.jump(`/maco_list?perm_id=${this.$route.query.perm_id}`);
        })
        .catch(err => console.error(err));
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      macoAPI.addMaco(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.$refs.dynamicForm.reset();
        this.subTableData = [];
        const props = this.$route.query;
        this.jump('/maco_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: props.perm_id,
              form_id: data.form_id
            })
          )
        });
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.maco_id = this.maco_id;
      formModel.maco_no = this.maco_no;
      formModel.macoPartList = this.subTableData;
      return formModel;
    },
    addRow() {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.only_type = 1;
      this.subTableData.push(obj);
    },
    delRow() {
      let { tableData, map } = deleteTableRow('maco_part_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    async getESFTableData() {
      this.ESFLoadFlag = true;
      await macoAPI.getRequByType2List(this.$refs.ESFTable.searchForm).then(({ data }) => {
        this.ESFTableData = data.list;
        this.ESFTotalPage = data.total;
        this.ESFLoadFlag = false;
      });
    },
    importESFConfirm(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.requ_no = row.requ_no;
      obj.scon_stff_name = row.requ_stff_name;
      obj.mtrb_name = row.prod_name;
      obj.mtrb_spec = row.prod_spec;
      this.subTableData.push(obj);
      this.ESFDialogVisible = false;
    },
    ESFDialogRefresh() {
      this.$refs.ESFTable.resetFields();
      this.getESFTableData();
    },
    getStatusName(status) {
      switch (status) {
        case 0:
          return { name: '未报价', type: 'info' };
        case 1:
          return { name: '报价中', type: 'warning' };
        case 2:
          return { name: '已报价', type: 'success' };
      }
    }
  }
};
</script>

<style scoped></style>
